<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ANNUAL GROSS REVENUE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="year_of"
              class="mx-2"
              dense
              outlined
              label="Year Of"
              :items="month_of_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="save_request"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="selection"
              class="mx-2"
              dense
              outlined
              label="Data"
              :items="['Current','Non Current']"
              :rules="rules.combobox_rule"
              @change="selected_selection"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="save_request"
            ></v-select>
          </v-flex>
          <v-select
            v-model="view_by"
            class="mx-2"
            dense
            outlined
            label="View By"
            :items="['Line Graph','Figures']"
            :rules="rules.combobox_rule"
            @change="save_request"
          ></v-select>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-form>
      <div v-if="view_by==='Figures'">
        <v-data-table dense
                      class="mt-3"
                      :headers="headers"
                      :items="data_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:top v-if="data_items.length>0">
            <h2 v-if="!saving_data" class="mx-2">PRINT
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </h2>

            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.category }}
              </td>
              <td>
                {{item.dec===0?'': formatPrice(item.dec) }}
              </td>
              <td>
                {{item.jan===0?'': formatPrice(item.jan) }}
              </td>
              <td>
                {{item.feb===0?'': formatPrice(item.feb) }}
              </td>
              <td>
                {{item.mar===0?'': formatPrice(item.mar) }}
              </td>
              <td>
                {{item.apr===0?'': formatPrice(item.apr) }}
              </td>
              <td>
                {{item.may===0?'': formatPrice(item.may) }}
              </td>
              <td>
                {{item.jun===0?'': formatPrice(item.jun) }}
              </td>
              <td>
                {{item.jul===0?'': formatPrice(item.jul) }}
              </td>
              <td>
                {{item.aug===0?'': formatPrice(item.aug) }}
              </td>
              <td>
                {{item.sep===0?'': formatPrice(item.sep) }}
              </td>
              <td>
                {{item.oct===0?'': formatPrice(item.oct) }}
              </td>
              <td>
                {{item.nov===0?'': formatPrice(item.nov) }}
              </td>

            </tr>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <LinearChart
          :key="this.$vuetify.theme.dark || this.key"
          :dataPoints="this.dataPoints"
          :report_title="'ANNUAL GROSS REVENUE REPORT'"
          :report_details="this.report_details"
        ></LinearChart>
      </div>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import LinearChart from '@/views/pages/charts/Chart'

  const initialState = () => {
    return {
      key: 0,
      saving_data: false,
      alert: false,
      option_items: [],
      category_items: [],
      dataPoints: [],
      month_of_items: [],
      data_items: [],
      category_id: '',
      option: '',
      alert_message: '',
      report_details: '',
      selection: '',
      year_of: '',
      view_by: '',
      total_amount: '0.00',
      headers: [
        {text: 'Category', value: 'date_of_deposit', sortable: false},
        {text: 'Dec.', value: 'last_name', sortable: false},
        {text: 'Jan.', value: 'last_name', sortable: false},
        {text: 'Feb.', value: 'last_name', sortable: false},
        {text: 'Mar.', value: 'last_name', sortable: false},
        {text: 'Apr.', value: 'last_name', sortable: false},
        {text: 'May', value: 'last_name', sortable: false},
        {text: 'Jun.', value: 'last_name', sortable: false},
        {text: 'Jul.', value: 'last_name', sortable: false},
        {text: 'Aug.', value: 'last_name', sortable: false},
        {text: 'Sep.', value: 'last_name', sortable: false},
        {text: 'Oct.', value: 'last_name', sortable: false},
        {text: 'Nov.', value: 'last_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
      LinearChart,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('category', ['list_of_categories_by_current']),
      ...mapActions('bank_transaction', ['annual_gross_revenue_income']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      async selected_selection() {
        this.data_items = []
        this.option_items = []
        this.category_id = ''
        if (this.selection != '') {
          this.list_of_categories_by_current({
            is_current: this.selection === 'Current' ? 1 : 0,
          })
            .then(response => {
              this.category_items = response.data
              this.category_items.splice(0, 0, {
                id: -1,
                category: 'All',
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async save_request() {
        this.data_items = []
        this.key++
        this.dataPoints = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        if (this.$refs.form.validate()) {
          await this.annual_gross_revenue_income({
            year_of: this.year_of,
            category_id: this.category_id,
            is_current: this.selection === 'Current',
          })
            .then(response => {
              this.data_items = response.data
              this.alert = false
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            });
          if (this.view_by != 'Figures') {
            this.report_details = 'Year of: ' + this.year_of
            var dataPoints = []
            await this.data_items.forEach(function (item) {
              var randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
              if (item.id >= 0) {
                var dd = []
                Object.entries(item).map(([key, val] = entry) => {
                  var randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
                  if (key != 'category' && key != 'id') {
                    dd.push({
                      label: key,
                      y: val,
                      color: "#" + randomColor
                    })
                  }
                });
                dataPoints.push({
                  type: "spline",
                  name: item.category,
                  showInLegend: true,
                  startAngle: 90,
                  yValueFormatString: "#,##0.00",
                  toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span>, {y}",
                  dataPoints: dd,
                })
              }
            })
            this.dataPoints = dataPoints
            this.key++
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async selected_category(value) {
        this.data_items = []
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          switch (this.category_items[index].category) {
            case "Damayan":
              this.option_items = ['All', 'DAM']
              break
            case "Chapel":
              this.option_items = ['All', 'SERV']
              break
            case "HO":
              this.option_items = ['All', 'TRUST FUND', 'EXPENSE', 'RESERVE FUND', 'INVESTMENT']
              break
          }
          this.option = 'All'
        }
        await this.save_request()
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []


          widths = [90, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49, 49]
          payments_array.push([
            {text: 'Category', alignment: 'left', style: 'main_info',},
            {text: 'Dec.', alignment: 'left', style: 'main_info'},
            {text: 'Jan.', alignment: 'left', style: 'main_info'},
            {text: 'Feb.', alignment: 'left', style: 'main_info'},
            {text: 'Mar.', alignment: 'left', style: 'main_info'},
            {text: 'Apr.', alignment: 'left', style: 'main_info'},
            {text: 'May', alignment: 'left', style: 'main_info'},
            {text: 'Jun.', alignment: 'left', style: 'main_info'},
            {text: 'Jul.', alignment: 'left', style: 'main_info'},
            {text: 'Aug.', alignment: 'left', style: 'main_info'},
            {text: 'Sep.', alignment: 'left', style: 'main_info'},
            {text: 'Oct.', alignment: 'left', style: 'main_info'},
            {text: 'Nov.', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.forEach(function (item) {
            payments_array.push([
              {
                text: item.category,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.dec === 0 ? '' : (item.dec / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jan === 0 ? '' : (item.jan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.feb === 0 ? '' : (item.feb / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.mar === 0 ? '' : (item.mar / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.apr === 0 ? '' : (item.apr / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.may === 0 ? '' : (item.may / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jun === 0 ? '' : (item.jun / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.jul === 0 ? '' : (item.jul / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.aug === 0 ? '' : (item.aug / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.sep === 0 ? '' : (item.sep / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.oct === 0 ? '' : (item.oct / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.nov === 0 ? '' : (item.nov / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Year of: ',
                    {
                      text: this.year_of,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'landscape',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '______________________________________________________________________________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'ANNUAL GROSS REVENUE REPORT',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
